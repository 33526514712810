import axios from 'axios';
import socketIOClient from 'socket.io-client';

let socket = socketIOClient()

import 'regenerator-runtime/runtime'

class Postier{

    constructor(){
        this.id_Site="0"
    }
    
    //CONST

    async getClients(username,password,setClients,setId_user){
        axios.post('/clients',{username,password}).then(res=>{
            const clients = res.data.map(c=>c.client)

            console.log({clients,ctl:res.data})
    
            setClients(clients)
            setId_user(res.data[0].id_user);

        }).catch(err=>{
            console.log(res)
            alert("une erreur est survenue")
        })

        
        

    }

    async getLoginSites(CLIENT,id_user,setSites){

        const res = await axios.post('/Sites',{CLIENT,id_user})

        const sites = res.data
        console.log({sites})

        setSites(sites)

    }

    async getSite(setSite,id_site,CLIENT){
        const res = await axios.post('/postier/site',{id_site,CLIENT}) 
        console.log({"getSiteName":res.data})
        setSite(res.data)

    }

    async getSite(setSite,id_site,CLIENT){

        console.log({id_site,CLIENT})

        const res = await axios.post('/postier/siteName',{id_site,CLIENT}) 

        if(res.data[0]){
            const {site_name} = res.data[0]

            console.log({site:res.data})
    
            setSite(site_name)
        }
        
    }

    async getSiteName(setSite,id_site,CLIENT){
        console.log({id_site,CLIENT})
        const res = await axios.post('/postier/siteName',{id_site,CLIENT}) 

        if(res.data[0]){
            const {client_name} = res.data[0]

            console.log({client_name})
    
            setSite(client_name)
        }


        

    }


    async getIdSite(setSite,setClient){
        const url = window.location.href
        const id_Site=url.split('//')[1].split('/')[2]
        console.log({id_Site})
        setSite(id_Site)

        const client=url.split('//')[1].split('/')[1]
        console.log({client});
        client?setClient(client):null;

    }

    //OCCUPATION

    async getOccupationSites(setSites,CLIENT){
        console.log({CLIENT})
        const res = await axios.post('/postier/occupationSites',{CLIENT})

        setSites(res.data)
    }

    

    async horairesSocket(setresult){
        console.log("horaire socket")
        socket.on('occupation',(data)=>{
            setresult(data)
        })
    }

    async idSocket({id_site},client){
        socket.emit("id_site",{id_site,client})
    }

    async limiteSocket(limite){
        socket.emit("limite",limite)
    }

    //HORRAIRE
    async changeHoraires(time,id_site,client){

        let res = await axios.post(`/postier/changeHours`,{hours:time,id_site,CLIENT:client})

    }

    async createHoraires(time,id_site,client){

        let res = await axios.post(`/postier/createHoraires`,{hours:time,id_site,CLIENT:client})


    }


    async getHoraires(setdates,id_site,client){
        alert(client)
        console.log({id_site,client})
        let res = await axios.post(`/postier/gethoraires`,{
            id_site,CLIENT:client
        })

        console.log(res.data)

        setdates(res.data)
    }

    async getExpHoraires(setdates,id_site,client){

        let res = await axios.post(`/postier/getExceptionalhoraires`,{id_site,CLIENT:client})
        setdates(res.data)
    }

    async getHorairesSites(setSites){
        let res = await axios.post(`/postier/getHoraireSites`,{id_site:this.id_Site})
        console.log(res.data)
        setSites(res.data)
    }

    async deleteExpHoraires(day,id_site,client){
        let res = await axios.post(`/postier/deleteExp`,{day,id_site,CLIENT:client})
        console.log(res.data)
    }

    //get AllSites

    async getAllSites(setUsers){

        const res = await axios.post(`/postier/allsites`,{id_site:this.id_Site})

        setUsers(res.data)

    }
    

    // GET SITE NAME
    async getENV(setsite){
        let res = await axios.post('/env/variables',{id_site:this.id_Site})
        const {siteName} = res.data
        setsite(siteName)
        return res.data
    }

    //GET EMAILS
    async getEmails(setUsers,idSite,client){

        const res = await axios.post(`/postier/getMails`,{idSite,CLIENT:client})

        setUsers(res.data)

    }

    //ACTIVATE EMAIL
    async activateEmails(id_site,email,is_active,client,id_user){

        const res = await axios.post(`/postier/activateMail`,{
            id_site,email,is_active,CLIENT:client,id_user
        })

        console.log(res.data)

        location.reload()

    }

    //UPDATE ADDRESS
    async updateAddress(id_site,old_email,new_email,client,id_user){
        const res = await axios.post(`/postier/updateMail`,{
            id_site,old_email,new_email,CLIENT:client,id_user
        })

        location.reload()

    }

    //INSERT ADDRESS
    async newAddress(site_name,new_email,id_site,client,id_user){

        const res = await axios.post(`/postier/newMail`,{
            site_name,new_email,id_site,CLIENT:client,id_user
        })

        location.reload()

    }

    //Delete ADDRESS
    async suppressAddress({client,client_name,site_name,email,is_active},id_site,CLIENT,id_user){
        
        const res = await axios.post(`/postier/delete`,{
            client,client_name,site_name,email,is_active,id_site,id_user,CLIENT
        })

        console.log(res.data)

        location.reload()

    }

    //GET Cams
    async getCams(setUsers,id_site,CLIENT){

        const res = await axios.post(`/postier/getCams`,{id_site,CLIENT})
        setUsers(res.data)

    }

    //ACTIVATE Cam
    async activateCam(is_active,id_cam,id_site,CLIENT,id_user,entity_analyzed_name){

        const res = await axios.post(`/postier/activateCam`,{
            id_cam,is_active,id_site,CLIENT,id_user,entity_analyzed_name
        })

        // console.log({is_active,id_cam})

        location.reload()

    }

    //SET Flux Cam
    async setFluxCam({is_in,is_out,id_entity_analyzed,is_inverted,id_site,client,id_user,entity_analyzed_name,text,status}){

        const res = await axios.post(`/postier/setFluxCam`,{
            id_entity_analyzed,is_out,is_in,is_inverted,id_site,CLIENT:client,id_user,entity_analyzed_name,text,status
        })

        // console.log({is_active,id_cam})

        location.reload()

    }

    //SEUIL

    async getSeuilSites(setUsers,id_site,client){

        const res = await axios.post(`/postier/seuilsites`,{id_site,CLIENT:client})

        setUsers(res.data)

    }

    async getSeuilLimit(site_name,setseuil,setTime,client){

            const res = await axios.post(`/postier/getLimit`,{site_name,CLIENT:client})
            
            const data = res.data;
            
            if(data[0]){
                console.log(data[0]);
                setseuil(data[0].limit_val)
                setTime(data[0].limit_time)
            }
            
        

    }

    async setTimeLimit(time,id_site,client){

        const res = await axios.post(`/postier/setTime`,{time,id_site,CLIENT:client})

    }

    async setSeuilLimit(seuil,id_site,client){

        const res = await axios.post(`/postier/setLimit`,{limite:seuil,id_site,CLIENT:client})

    }

    async getSeuilFrequence(site_name,setFrequence,id_site,client){

        const res = await axios.post(`/postier/getFrequence`,{site_name,id_site,CLIENT:client});

        res.data[0]?setFrequence(res.data[0].period):null;


    }

    async updateFrequence(site_name,frequence,client){

        const res = await axios.post(`/postier/updateFrequence`,{site_name,frequence,CLIENT:client})


        console.log(res.data)


    }

    reset(id_site,CLIENT){
        axios.post('/postier/reset',{id_site,CLIENT})
    }

}

const postier = new Postier()

export {postier,socket}
