import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { Select, Option } from "select-react-app";
import { postier } from "./Postier";

function Login() {
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState({
    id_user: 6,
    id_site: 13000,
    client: "cc018",
    site_name: "LGE RG",
    client_name: "La Grande Epicerie Rive Gauche",
  });
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("");

  const [id_user, setId_user] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [page, setPage] = useState(1);

  const [post, setPost] = useState("/login");

  const [update, setUpdate] = useState(0);

  // useEffect(() => {
  //     setUpdate(update=>update+1)

  // }, [])

  useEffect(() => {
    if (clients[0]) {
      setClient(clients[0]);
      localStorage.setItem("client", clients[0]);
    } else {
      setPage(1);
    }
  }, [clients, update]);

  useEffect(() => {
    id_user != "" ? getSite() : null;
  }, [id_user]);

  useEffect(() => {
    if (sites[0]) {
      // alert(JSON.stringify(data));
      localStorage.setItem("id_site", sites[0].id_site);
      localStorage.setItem("client", sites[0].client);
      localStorage.setItem("site_name", sites[0].site_name);
      localStorage.setItem("client_name", sites[0].client_name);
      localStorage.setItem("site", JSON.stringify(sites[0]));
      setSite(sites[0]);
    }
  }, [sites]);

  const getclients = (e) => {
    e.preventDefault();
    setPage(2);
    postier.getClients(username, password, setClients, setId_user);
    setUpdate((update) => update + 1);
  };

  const getSite = (e) => {
    // e.preventDefault()
    console.log("Sites");
    setPage(3);
    postier.getLoginSites(client, id_user, setSites);
  };

  const saveUser = (e) => {
    localStorage.setItem("id_user", id_user);

    const id_site = e.target.elements.id_site.value;
    // localStorage.setItem("id_site", id_site);
  };

  //Page de connexion 1

  const Login = (
    <form id="form" onSubmit={getclients} className="contain">
      <div className="LoginTitle">veuillez vous connecter </div>
      <input
        id="username"
        name="username"
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
        className=" formulaire"
        placeholder="username"
      />
      <input
        id="password"
        name="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        type="password"
        className="formulaire"
        placeholder="password"
      />
      <button id="submit" type="submit" className="btn-info formulaire">
        Se Connecter
      </button>
    </form>
  );

  //Page 2

  const selectClient = (
    <form id="form" onSubmit={getSite} className="contain">
      <div className="LoginTitle"> chargement... </div>
    </form>
  );

  //Page3

  const Accueil = (
    <form
      id="form"
      className="contain"
      onSubmit={saveUser}
      action={`${post}`}
      method="post"
    >
      <div className="LoginTitle"> Choisissez le site </div>
      <input
        id="id_user"
        name="id_user"
        autocomplete="false"
        hidden
        value={id_user}
      />
      <input
        id="client"
        name="client"
        hidden
        autocomplete="false"
        value={client}
        onChange={(e) => {}}
        className=" formulaire"
        placeholder="client"
      />
      <input
        id="username"
        name="username"
        autocomplete="false"
        hidden
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
        className=" formulaire"
        placeholder="username"
      />
      <input
        id="password"
        name="password"
        autocomplete="false"
        hidden
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        type="password"
        className="formulaire"
        placeholder="password"
      />
      <select name="id_site" value={site.id_site} className="formulaire" hidden>
        {sites.map((site) => (
          <option key={site.id_site} value={site.id_site}>
            {site.client_name}
          </option>
        ))}
      </select>
      <Select
        value={site}
        displayValue="client_name"
        width="50%"
        className="formulaire"
      >
        {sites.map((site) => (
          <Option
            key={site}
            value={site}
            setValue={(data) => {
              setSite(data);
              // alert(JSON.stringify(data));
              localStorage.setItem("id_site", data.id_site);
              localStorage.setItem("client", data.client);
              localStorage.setItem("site_name", data.site_name);
              localStorage.setItem("client_name", data.client_name);
              localStorage.setItem("site", JSON.stringify(data));
            }}
          >
            {site.client_name}
          </Option>
        ))}
      </Select>
      <button id="submit" type="submit" className="btn-info formulaire">
        Accéder au site
      </button>
    </form>
  );

  return (
    <>
      {page == 1 ? Login : null}
      {page == 2 ? selectClient : null}
      {page == 3 ? Accueil : null}
    </>
  );
}

render(<Login />, document.getElementById("app"));

